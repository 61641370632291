<script>
import { Card } from '@components/Card';
import ClusterPermissionsEditor from '@shell/components/form/Members/ClusterPermissionsEditor';

export default {
  components: {
    Card,
    ClusterPermissionsEditor
  },

  props: {
    onAdd: {
      type:    Function,
      default: () => {}
    }
  },

  data() {
    return { bindings: [] };
  },

  methods: {
    close() {
      this.$emit('close');
    },

    apply() {
      this.onAdd(this.bindings);
      this.close();
    },
  }
};
</script>

<template>
  <Card
    class="prompt-rotate"
    :show-highlight-border="false"
  >
    <h4
      slot="title"
      v-clean-html="t('addClusterMemberDialog.title')"
      class="text-default-text"
    />

    <div
      slot="body"
      class="pl-10 pr-10"
    >
      <ClusterPermissionsEditor
        v-model="bindings"
        :use-two-columns-for-custom="true"
      />
    </div>

    <div
      slot="actions"
      class="buttons"
    >
      <button
        class="btn role-secondary mr-10"
        @click="close"
      >
        {{ t('generic.cancel') }}
      </button>

      <button
        class="btn role-primary"
        @click="apply"
      >
        {{ t('generic.add') }}
      </button>
    </div>
  </Card>
</template>
<style lang='scss' scoped>
  .prompt-rotate {
    margin: 0;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
</style>
